import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import swal from "sweetalert";
import cross from "../../assets/images/close.png";
import { useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import Loader from "../../Components/Common/Loader";
import { ProgressSpinner } from "primereact/progressspinner";
import { TailSpin } from "react-loader-spinner";
import useAdmin from "../../Components/Shared/useAdmin";

export default function AddVideo() {
  const [user, loading] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const email = user?.email;
  const [admin, adminLoading] = useAdmin(user);

  const isUserAdminQuery = useQuery({
    queryKey: ["users"],
    queryFn: () =>
      fetch(`https://api.islamicposhak.com/api/users/email/${email}`).then(
        (res) => res.json()
      ),
  });

  const videoQuery = useQuery({
    queryKey: ["video"],
    queryFn: () =>
      fetch("https://api.islamicposhak.com/api/video", {
        headers: {
          ContentType: "application/json",
        },
      }).then((res) => res.json()),
  });

  const videos = videoQuery.data;
  const refetch = videoQuery.refetch;

  const data = isUserAdminQuery.data.data[0];
  const isLoading = isUserAdminQuery.isLoading;
  const error = isUserAdminQuery.error;

  const handleSubmit = (e) => {
    setLoadingData(true);

    e.preventDefault();
    const link = e.target.link.value;

    const body = {
      data,
      link,
    };

    if (data?.role !== "admin") {
      swal("Oops", "You Are Not Authorized To Add Video", "error");
      setLoadingData(false);
      return;
    } else {
      fetch("https://api.islamicposhak.com/api/video", {
        method: "POST",
        headers: {
          authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((res) => {
        if (res.ok) {
          swal("Yayy", "Video Added Successfully", "success");
          refetch();
          setLoadingData(false);
          e.target.link.value = "";
        } else {
          swal("Error", res.message, "error");
        }
      });
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  if (error) {
    console.log(error);
  }
  if (loading || isLoading || data == undefined) {
    return <Loader />;
  }

  const handleDelete = async (id) => {
    const response = await fetch(
      `https://api.islamicposhak.com/api/video/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data) {
      swal("Yayy", "Video Deleted Successfully", "success");
      refetch();
    } else {
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <div>
      {adminLoading || isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="p-1 lg:p-24">
              <h2 className="text-black dark:text-white text-center text-xl p-5 font-bold ">
                Add Video
              </h2>
              <form className="p-5" onSubmit={handleSubmit}>
                <label
                  for="link"
                  className="relative block overflow-hidden rounded-md border border-gray-300  px-3 pt-3 shadow-sm  my-2 focus-within:ring-1 w-full lg:w-96  m-auto"
                >
                  <input
                    type="text"
                    id="link"
                    placeholder="Youtube Link"
                    required
                    className="peer h-8 w-full text-black   text-[15px] border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  />

                  <span className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700  transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs">
                    Youtube Link
                  </span>
                </label>

                <div className="flex justify-center">
                  {loadingData ? (
                    <TailSpin
                      visible={true}
                      height="40"
                      width="40"
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    <input
                      type="submit"
                      value={"Add Video"}
                      className="p-4 text-white   rounded-lg  bg-green-400 cursor-pointer text-sm 
                        hover:bg-green-500  mt-5 w-full text-center text-[15px] lg:w-96
                       "
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="px-2 ">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <TailSpin
                  visible={true}
                  height="40"
                  width="40"
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
                  {videos?.data?.map((tutorial) => (
                    <div className=" overflow-x-hidden  w-full ">
                      <div
                        dangerouslySetInnerHTML={{ __html: tutorial.link }}
                      ></div>
                      <div class="px-2 md:px-3 pb-2 md:pb-3 mt-5">
                        <button
                          onClick={() => handleDelete(tutorial?._id)}
                          class="text-white w-[100px] bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-[10px] md:text-sm px-3 py-2.5 mt-5 text-center  top-11"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
