import { useQuery } from "@tanstack/react-query";
import React from "react";
import { TailSpin } from "react-loader-spinner";

export default function Tutorials() {
  const {
    data: tutorials,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["video"],
    queryFn: () =>
      fetch("https://api.islamicposhak.com/api/video").then((res) =>
        res.json()
      ),
  });

  return (
    <div className="px-2 md:px-32 my-20">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <TailSpin
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
            {tutorials?.data?.map((tutorial) => (
              <div className=" overflow-x-hidden  w-full video-container">
                <div dangerouslySetInnerHTML={{ __html: tutorial.link }}></div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
