import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-1.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { useQuery } from "@tanstack/react-query";

import { Sidebar } from "primereact/sidebar";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { Link } from "react-router-dom";
import Cart from "../Home/Cart";
import auth from "../../firebase.init";
import MobileTopNav from "./MobileTopNav";
import MobileSideMenu from "./MobileSideMenu";
import avatar from "../../assets/images/avatar.jpg";
import { FaWhatsapp, FaFacebookMessenger } from "react-icons/fa";

export default function Navbar() {
  const [user] = useAuthState(auth);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleNav, setVisibleNav] = useState(false);
  const [visibleTopNav, setVisibleTopNav] = useState(false);

  const [localCartData, setLocalCartData] = useState();

  const email = user?.email;
  const handleSignOut = () => {
    signOut(auth);
  };

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: () =>
      fetch(`https://api.islamicposhak.com/api/users/email/${email}`).then(
        (res) => res.json()
      ),
  });
  const userInfo = usersQuery?.data?.data ? usersQuery?.data?.data[0] : "";
  const refetch = () => {
    usersQuery.refetch();
  };

  const cartProducts = JSON.parse(localStorage.getItem("cartData"));

  // useEffect(() => {
  //   if (!userInfo) {
  //     refetch();
  //   }
  // }, []);

  // const cartQuery = useQuery({
  //   queryKey: ["cart"],
  //   queryFn: () =>
  //     fetch(`https://api.islamicposhak.com/api/cart/${email}`).then((res) =>
  //       res.json()
  //     ),
  // });
  // const cartRefetch = () => {
  //   cartQuery.refetch();
  // };
  // const cartProducts = cartQuery.data?.data;
  // useEffect(() => {
  //   const cartData = JSON.parse(localStorage.getItem("cartData"));
  //   cartRefetch();
  //   setLocalCartData(cartData);
  // }, [cartProducts]);

  return (
    <div>
      <div>
        <header
          aria-label="Site Header  "
          className="w-full bg-white shadow-lg "
        >
          <div className="mx-auto flex h-20 max-w-screen-2xl items-center justify-between sm:px-6 lg:px-8  ">
            <div className="flex items-center gap-4 ">
              {user ? (
                <button
                  type="button"
                  className="p-2 lg:hidden"
                  onClick={() => setVisibleNav(true)}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}
              <div>
                <Sidebar
                  position="left"
                  visible={visibleNav}
                  onHide={() => setVisibleNav(false)}
                >
                  <MobileSideMenu
                    visibleNav={visibleNav}
                    setVisibleNav={setVisibleNav}
                  />
                </Sidebar>
              </div>
            </div>
            <Link to="/">
              <img className="h-24 w-24 lg:h-36 lg:w-36" src={logo} alt="" />
            </Link>

            <div className="flex flex-1 items-center justify-end gap-8 ">
              <nav
                aria-label="Site Nav"
                className="hidden lg:flex lg:gap-4 lg:text-xs lg:font-bold lg:uppercase lg:tracking-wide lg:text-gray-700 "
              >
                <Link
                  to="/"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  হোম
                </Link>
                <Link
                  to="/hijab"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  হিজাব
                </Link>
                <Link
                  to="/borka"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  বোরকা
                </Link>

                <Link
                  to="/all-products"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  সকল প্রোডাক্টস
                </Link>
                <Link
                  to="/gallery"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  গ্যালারী
                </Link>
                <Link
                  to="/reviews"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  রিভিউ
                </Link>

                {user && (
                  <Link
                    to="/dashboard"
                    className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                  >
                    ড্যাশবোর্ড
                  </Link>
                )}
                <Link
                  to="/tutorials"
                  className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                >
                  টিউটোরিয়াল
                </Link>

                {user ? (
                  <button
                    onClick={handleSignOut}
                    className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current"
                  >
                    লগ আউট
                  </button>
                ) : (
                  <Link
                    to="/login"
                    className="block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current "
                  >
                    লগ ইন / রেজিস্টার
                  </Link>
                )}

                <div class="flex flex-1 items-center justify-between gap-8 sm:justify-end">
                  <div class="flex gap-4">
                    {/* <div className="bg-white dark:bg-[#263449] hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400 m-auto dark:rounded-md">
                      <ThemeToggler />
                    </div> */}
                  </div>

                  {/* {user && ( */}
                  <>
                    <div className="">
                      <a
                        href="
                          https://www.facebook.com/islamiposhak"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookMessenger
                          className="text-[30px]  bg-white   transition-all p-1 rounded-md
                          
                            shadow-md"
                        />
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="
                        https://wa.me/+8801918090266"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaWhatsapp
                          className="text-[30px]  bg-white   transition-all p-1 rounded-md
                          
                            shadow-md"
                        />
                      </a>
                    </div>
                    <div className="">
                      <button
                        icon="pi pi-arrow-left"
                        onClick={() => setVisibleRight(true)}
                      >
                        <AiOutlineShoppingCart className="text-[30px]  bg-white   transition-all p-1 rounded-md shadow-md" />
                        {/* <span class="whitespace-nowrap rounded-full bg-green-100 px-1.5 py-0.5 text-sm text-green-700 relative left-4 bottom-10">
                          {cartProducts?.length}
                        </span> */}
                      </button>
                    </div>
                    <div>
                      <Sidebar
                        position="right"
                        visible={visibleRight}
                        onHide={() => setVisibleRight(false)}
                      >
                        <Cart
                          visibleRight={visibleRight}
                          setVisibleRight={setVisibleRight}
                        />
                      </Sidebar>
                    </div>
                  </>
                  {/* )} */}
                  {user ? (
                    <button
                      type="button"
                      class="group flex shrink-0 items-center rounded-lg transition"
                    >
                      <img
                        alt="profile"
                        src={avatar}
                        class="h-10 w-10 rounded-full object-cover"
                      />

                      <p class="ms-2 hidden text-left text-xs sm:block">
                        <strong class="block font-medium">
                          {userInfo?.role ? userInfo?.role : ""}
                        </strong>

                        <span class="text-gray-500">{email}</span>
                      </p>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </nav>
              <div className="block lg:hidden">
                <a
                  href="https://www.facebook.com/islamiposhak"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookMessenger className="text-[30px]  bg-white   transition-all p-1 rounded-md shadow-md" />
                </a>
              </div>
              <div className="block lg:hidden">
                <a
                  href="https://wa.me/+8801918090266"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="text-[30px]  bg-white   transition-all p-1 rounded-md shadow-md" />
                </a>
              </div>
              <div className="block lg:hidden">
                <button
                  icon="pi pi-arrow-left"
                  onClick={() => setVisibleRight(true)}
                >
                  <AiOutlineShoppingCart className="text-[30px]  bg-white   transition-all p-1 rounded-md shadow-md" />
                  {/* <span class="whitespace-nowrap rounded-full bg-green-100 px-1.5 py-0.5 text-sm text-green-700 relative left-4 bottom-10">
                    {cartProducts?.length}
                  </span> */}
                </button>
              </div>
              <div className="flex items-center gap-4 ">
                <button
                  type="button"
                  className="p-2 lg:hidden"
                  onClick={() => setVisibleTopNav(true)}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
                <div>
                  <Sidebar
                    position="right"
                    visible={visibleTopNav}
                    onHide={() => setVisibleTopNav(false)}
                  >
                    <MobileTopNav
                      visibleTopNav={visibleTopNav}
                      setVisibleTopNav={setVisibleTopNav}
                    />
                  </Sidebar>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}
